import isEmpty from 'lodash/isEmpty'

export default {
  name: 'ShopCertificates',
  data() {
    return {
      certificates:
        typeof this.$t('ShopCertificates.items') === 'object' && !isEmpty(this.$t('ShopCertificates.items'))
          ? this.$t('ShopCertificates.items')
          : [],
    }
  },
}

<script>
import { isEmpty } from 'lodash'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'

export default {
  mixins: [ImageUrlMixin],
  computed: {
    criticalContentBreakpoints() {
      return []
    },
    criticalContentPaths() {
      return []
    },
    criticalContentImageSettings() {
      return {
        crop: true,
        webP: true,
      }
    },
    criticalContentImageRatio() {
      return null
    },
  },
  methods: {
    getCriticalContentMetaPaths() {
      const breakpoints = this.criticalContentBreakpoints
      const elements = this.criticalContentPaths
      const imageRatio = this.criticalContentImageRatio
      const items = []

      if (elements.length > 0) {
        for (const element of elements) {
          if (!isEmpty(breakpoints)) {
            let upperBreakpoint = null
            for (const srcsetBreakpoint of breakpoints) {
              const normal = this.imageUrl({
                ...this.criticalContentImageSettings,
                pixelRatio: 1,
                src: element,
                height: imageRatio ? Math.floor(imageRatio * srcsetBreakpoint.imageWidth) : null,
                ...(srcsetBreakpoint.imageWidth && { width: srcsetBreakpoint.imageWidth }),
                ...(srcsetBreakpoint.imageHeight && { height: srcsetBreakpoint.imageHeight }),
              })
              const retina = this.imageUrl({
                ...this.criticalContentImageSettings,
                pixelRatio: 2,
                src: element,
                height: imageRatio ? Math.floor(imageRatio * srcsetBreakpoint.imageWidth) : null,
                ...(srcsetBreakpoint.imageWidth && { width: srcsetBreakpoint.imageWidth }),
                ...(srcsetBreakpoint.imageHeight && { height: srcsetBreakpoint.imageHeight }),
              })
              let media = `(min-width: ${srcsetBreakpoint.viewportMinWidth}px)`
              if (upperBreakpoint) {
                media = `${media} and (max-width: ${upperBreakpoint.viewportMinWidth - 1}px)`
              }

              items.push({
                rel: 'preload',
                as: 'image',
                href: retina,
                imagesrcset: `${normal} 1x, ${retina} 2x`,
                media,
              })
              upperBreakpoint = srcsetBreakpoint
            }
          } else {
            const normal = this.imageUrl({
              ...this.criticalContentImageSettings,
              pixelRatio: 1,
              src: element,
            })
            const retina = this.imageUrl({
              ...this.criticalContentImageSettings,
              pixelRatio: 2,
              src: element,
            })
            items.push({
              rel: 'preload',
              as: 'image',
              href: retina,
              imagesrcset: `${normal} 1x, ${retina} 2x`,
            })
          }
        }
      }

      return items
    },
  },
}
</script>

<template>
  <PageHomepage
    :featured-articles="featuredArticles"
    :block-homepage-content="blockHomepageContent"
    :featured-banners="featuredBanners ? featuredBanners : []"
    :featured-products="{mostSoldProducts, newestProducts, saledProducts}"
    :info-boxes="infoBoxes"/>
</template>

<script>
import Component from '~/sites/shop/pages/index'
export default {
  extends: Component,
  async asyncData(context) {
    const saledProducts = context.app.$themeSettings.components.PageHomepage.newestProducts.enable
      ? context.store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'sale', size: 8 })
      : null
    let result = await Promise.allValues(
      {
        componentResult: Component.asyncData(context),
        saledProducts,
      },
      [],
    )

    result = {
      ...result,
      ...result.componentResult,
    }

    if (context.app.$contentSettings.functions.ssrPropertyPicker && process.server) {
      const mixin = (await import('~/mixins/SSRPropertyPickMixin.vue')).default

      result = mixin.methods.pickKeysFromPayload({
        payload: result,
        rules: mixin.computed.ssrPayloadRules(),
        pickers: mixin.computed.ssrPayloadPickers(),
      })
    }

    await context.app.apolloProvider.defaultClient.cache.reset()
    return result
  },
}
</script>

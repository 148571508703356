import { get } from 'lodash'
import AppTitle from '@theme/components/atom/AppTitle'
import CriticalContentMixin from '~/mixins/CriticalContentMixin.vue'
import InfoBoxes from '@theme/components/content/InfoBoxes'
import LayoutBase from '@theme/components/shop/LayoutBase'
import SeoMixin from '~/mixins/SeoMixin'
import Slider from '@theme/components/content/Slider'
import TopCategories from '@theme/components/category/TopCategories'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

import { hydrateWhenVisible } from 'vue-lazy-hydration'

const AppImage = () => import('@theme/components/atom/AppImage')
const AppLink = () => import('@theme/components/atom/AppLink')
const Carousel = () => import('@theme/components/utils/Carousel')
const Divider = () => import('@theme/components/molecule/Divider')
const FeaturedArticles = () => import('@theme/components/content/FeaturedArticles')
const HomepageBranches = () => import('@theme/components/content/HomepageBranches/HomepageBranchesLazy')
const HomepageBrands = () => import('@theme/components/content/HomepageBrands')
const HomepageTextBlock = () => import('@theme/components/content/HomepageTextBlock')
const PageHomepageBelowUniqueSellingPointsHook = () =>
  import('@theme/components/shop/PageHomepageBelowUniqueSellingPointsHook')
const PageHomepageBetweenSliderAndUniqueSellingPointsHook = () =>
  import('@theme/components/shop/PageHomepageBetweenSliderAndUniqueSellingPointsHook')
const PageHomepageMainHeading = () => import('@theme/components/shop/PageHomepageMainHeading')
const ProductBox = () => import('@theme/components/product/ProductBox')
const ProductsRecommended = () => import('@theme/components/product/ProductsRecommended/ProductsRecommendedLazy')
const ProductsVisited = () => import('@theme/components/product/ProductsVisited/ProductsVisitedLazy')
const UniqueSellingPoints = () => import('@theme/components/shop/UniqueSellingPoints')

export default {
  components: {
    AppImage: hydrateWhenVisible(AppImage),
    AppLink: hydrateWhenVisible(AppLink),
    AppTitle,
    Carousel: hydrateWhenVisible(Carousel),
    Divider: hydrateWhenVisible(Divider),
    FeaturedArticles: hydrateWhenVisible(FeaturedArticles),
    HomepageBranches: hydrateWhenVisible(HomepageBranches),
    HomepageBrands: hydrateWhenVisible(HomepageBrands),
    HomepageTextBlock: hydrateWhenVisible(HomepageTextBlock),
    InfoBoxes,
    LayoutBase: LayoutBase,
    PageHomepageBelowUniqueSellingPointsHook: hydrateWhenVisible(PageHomepageBelowUniqueSellingPointsHook),
    PageHomepageBetweenSliderAndUniqueSellingPointsHook: hydrateWhenVisible(
      PageHomepageBetweenSliderAndUniqueSellingPointsHook,
    ),
    PageHomepageMainHeading: hydrateWhenVisible(PageHomepageMainHeading),
    ProductBox: hydrateWhenVisible(ProductBox),
    ProductsRecommended: hydrateWhenVisible(ProductsRecommended),
    ProductsVisited: hydrateWhenVisible(ProductsVisited),
    Slider,
    TopCategories,
    UniqueSellingPoints: hydrateWhenVisible(UniqueSellingPoints),
  },
  props: {
    blockHomepageContent: Object,
    featuredArticles: Array,
    featuredBanners: Array,
    featuredProducts: Object,
    infoBoxes: Array,
  },
  head() {
    return this.getSeoHead()
  },
  mixins: [CriticalContentMixin, SeoMixin, UtilityGridMixin],
  computed: {
    criticalContentBreakpoints() {
      return get(this.$themeSettings, 'components.Slider.image.srcsetBreakpoints').length
        ? this.$themeSettings.components.Slider.image.srcsetBreakpoints
        : Slider.props.srcsetBreakpointsDefaults.default(this.$themeSettings)
    },
    criticalContentPaths() {
      const elements = []

      if (this.featuredBanners.length > 0) {
        const banner = this.featuredBanners[0]
        elements.push(
          banner.main_image_translatable && banner.main_image_translatable.length > 0
            ? banner.main_image_translatable[0].path
            : banner.main_image[0].path,
        )
      }

      return elements
    },
  },
  methods: {
    getLCPElements() {
      return this.getCriticalContentMetaPaths()
    },
  },
}

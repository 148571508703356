<script>
export default {
  methods: {
    timeout(time) {
      return new Promise(resolve => {
        setTimeout(function() {
          resolve()
        }, time)
      })
    },
  },
}
</script>

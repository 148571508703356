import { render, staticRenderFns } from "./ShopCertificates.vue?vue&type=template&id=7a9203ec&"
import script from "./ShopCertificates.js?vue&type=script&lang=js&"
export * from "./ShopCertificates.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ShopCertificates.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fcvb%2Fcomponents%2Fshop%2FShopCertificates%2FShopCertificates.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./ShopCertificates.lang.de.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fcvb%2Fcomponents%2Fshop%2FShopCertificates%2FShopCertificates.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)
import block2 from "./ShopCertificates.lang.sk.yaml?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fcvb%2Fcomponents%2Fshop%2FShopCertificates%2FShopCertificates.vue&lang=yaml"
if (typeof block2 === 'function') block2(component)

export default component.exports
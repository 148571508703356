import { hydrateWhenVisible } from 'vue-lazy-hydration'
import PageHomepage from 'themes/base/components/shop/PageHomepage'
import ShopCertificates from '@theme/components/shop/ShopCertificates'
import isEmpty from 'lodash/isEmpty'

const DiscountsOverview = () => import('@theme/components/content/DiscountsOverview')
const TabSwitch = () => import('@theme/components/shop/TabSwitch')

export default {
  mixins: [PageHomepage],
  components: {
    DiscountsOverview: hydrateWhenVisible(DiscountsOverview),
    TabSwitch: hydrateWhenVisible(TabSwitch),
    ShopCertificates,
  },
  data() {
    return {
      productSections: {
        value: null,
      },
    }
  },
  created() {
    this.productSections.value = this.productBoxSections[0]?.value
  },
  computed: {
    productBoxSections() {
      const sections = []

      if (this.featuredProducts.saledProducts && !isEmpty(this.featuredProducts.saledProducts)) {
        sections.push({ title: this.$t('Homepage.products.saled.heading'), value: 'saledProducts' })
      }

      if (this.featuredProducts.mostSoldProducts && !isEmpty(this.featuredProducts.mostSoldProducts)) {
        sections.push({ title: this.$t('Homepage.products.featured.heading'), value: 'mostSoldProducts' })
      }

      if (this.featuredProducts.newestProducts && !isEmpty(this.featuredProducts.newestProducts)) {
        sections.push({ title: this.$t('Homepage.products.newest.heading'), value: 'newestProducts' })
      }

      return sections
    },
  },
}
